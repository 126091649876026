import { useRecoilState } from "recoil"
import { ContextTypesState } from "recoilStates"
import { AddBlockStyles } from "./AddBlock.styled"
import CloseIcon from "@mui/icons-material/Close"
import { Block, BlockRecord } from "models/Components.model"
import { useColors } from "hooks/useColors"
import { GetAddBlockData } from "./AddBlock.data"

type AddBlockInput = {
    onClose(): void
    onAdd(item: Block): void
}
const AddBlock = ({ onAdd, onClose }: AddBlockInput) => {
    const [contextTypes] = useRecoilState<BlockRecord>(ContextTypesState)
    const { getColorByIndex } = useColors()

    return (
        <AddBlockStyles className="AddBlock cover center animate__animated animate__fadeIn">
            <div className="AddBlock-Window animate__animated animate__fadeInDown">
                <CloseIcon className="AddBlock-Close" onClick={onClose} />
                <div className="AddBlock-Content">
                    {Object.entries(contextTypes).map(([id, item], index) => {

                        const { name, image } = GetAddBlockData(item.name)

                        return (
                            <div
                                key={id}
                                className="AddBlock-Item center"
                                onClick={() => onAdd(item)}
                                style={{ backgroundColor: getColorByIndex(index) }}
                            >
                                <div className="AddBlock-Item-Image">
                                    <img src={`/images/items/${image}.jpg`} />
                                </div>
                                <label>{name}</label>
                            </div>
                        )
                    })}
                </div>
            </div>
        </AddBlockStyles>
    )
}

export { AddBlock }
