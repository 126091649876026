import styled from "@emotion/styled"

export const BlockItemImageStyles = styled.div`
    margin-bottom: 30px;
    .BlockItemImage {
        &-Image {
            width: 400px;
        }
        &-File {
            width: 500px;
            height: 300px;
            position: relative;
            &-Layer {
                position: absolute;
                background-color: #e4f0fc;
                z-index: 1;
                pointer-events: none;
                border-style: solid;
                border-width: 1px;
                border-color: #abd6ff;
                &-Icon {
                    color: #abd6ff;
                    width: 100px;
                    height: 100px;
                }
                &-Image {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        &-Input {
            position: absolute;
            cursor: pointer;
        }
    }
`