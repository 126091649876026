import { Block } from "models/Components.model"
import { useEffect, useState } from "react"
import { ContentService } from "services/content.service"
import { BlockItemRelationStyles } from "./BlockItemRelation.styled"
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

type Service = {
    id: string
    name: string
    attributes?: {
        createdAt: string,
        locale: string,
        name: string,
        publishedAt: string,
        updatedAt: string
    }
}
type ServiceRecord = Record<string, Service>
type BlockItemServiceInput = {
    block: Block
    onChange(value: any): void
}
const BlockItemRelation = ({ block, onChange }: BlockItemServiceInput) => {
    const [data, setData] = useState<ServiceRecord>({})
    const [selected, setSelected] = useState<ServiceRecord>(
        block?.value?.reduce((acum: ServiceRecord, item: Service) => {
            acum[item.id] = item
            return acum
        }, {}) || {}
    )

    useEffect(() => {
        const service = block.target
        ContentService.getService(service).then((data) => {
            setData(
                data.data.reduce((acum: ServiceRecord, item: Service) => {
                    acum[item.id] = item
                    return acum
                }, {}),
            )
        })
    }, [])

    const onAdd = (service: Service) => {
        const data = {
            ...selected,
            [service.id]: service,
        }
        setSelected(data)
    }

    const onRemove = (service: Service) => {
        const copy = Object.assign({}, selected)
        delete copy[service.id]
        setSelected(copy)
    }

    useEffect(() => {
        onChange(Object.entries(selected).reduce((acum: Service[], [,service]) => {
            acum.push(service)
            return acum
        }, []))
    }, [selected])


    return (
        <BlockItemRelationStyles className="BlockItemRelation">
            <div className="BlockItemRelation-Options">
                <p>Disponibles</p>
                <ul>
                    {Object.entries(data)
                        .filter(([id]) => !selected[id])
                        .map(([id, service]) => (
                            <li key={id} onClick={() => onAdd(service)}>
                                <AddIcon/>
                                <label>{service.name || service.attributes?.name}</label>
                            </li>
                        ))}
                </ul>
            </div>

            <div className="BlockItemRelation-Selected">
                <p>Seleccionados</p>
                <ul>
                    {Object.entries(selected).map(([id, service]) => (
                        <li key={id} onClick={() => onRemove(service)}>
                            <DeleteIcon/>
                            <label>{service.name || service.attributes?.name}</label>
                        </li>
                    ))}
                </ul>
            </div>
        </BlockItemRelationStyles>
    )
}

export { BlockItemRelation }
