import styled from "@emotion/styled"

export const BlockItemComponentStyled = styled.div`
    min-width: 600px;
    margin-bottom: 30px;
    .BlockItemComponent {
        &-Add {
            text-transform: uppercase;
            cursor: pointer;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #007efe;
            cursor: pointer;
            width: 100%;
            border-style: solid;
            border-width: 1px;
            border-color: #e4eaf4;
            height: 35px;
            label {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 14px;
                letter-spacing: 0.5px;
                pointer-events: none;
                display: block;
                margin-left: 5px;
            }
        }
        &-Item {
            animation-duration: 200ms;
            &-Header {
                height: 34px;
                width: 100%;
                border-style: solid;
                border-width: 1px;
                border-color: #e4eaf4;
                border-top-style: none;
                align-items: center;
                font-size: 14px;
                padding:  0 20px;
                color: gray;
                cursor: pointer;
                user-select: none;
                display: grid;
                grid-template-columns: auto max-content;
                transition: all ease 200ms;
                &:active {
                    transform: scale(0.98);
                }
                label {
                    pointer-events: none;
                }
                &-Button {
                    color: #7f7f7f;
                    width: 30px;
                    svg {
                        pointer-events: none;
                    }
                }
            }
            &-Content {
                padding: 30px 20px;
                background-color: #fafafb;
                border-style: solid;
                border-top-style: none;
                border-width: 1px;
                border-color: #e4eaf4;
                &-Title {
                    margin-top: 0;
                    font-size: 14px;
                }
            }
            &-Selected {
                .BlockItemComponent-Item-Header {
                    border-style: solid !important;
                    background-color: #e4f0fc;
                    color: #007fff;
                    border-color: #abd6ff;
                    transform: scale(1.01);
                }
            }
            &:last-child {
                .BlockItemComponent-Item-Header{
                    border-bottom-style: none;
                }
                .BlockItemComponent-Item-Content{
                    border-bottom-style: none;
                }
            }
        }
    }
`