import React from "react"
import ReactDOM from "react-dom"
import "animate.css"
import 'react-toastify/dist/ReactToastify.css'
import reportWebVitals from "./reportWebVitals"
import { Global } from "@emotion/react"
import { CoommonStyles } from "./styles/common"
import { AppProvider } from "./context"
import { Home } from "./pages"
import { RecoilRoot } from "recoil"
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ToastContainer } from "react-toastify"

declare global {
    interface Window {
        setLoading(val: boolean): void
    }
}

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <Global styles={CoommonStyles} />
            <ToastContainer></ToastContainer>
            <AppProvider>
                <Home />
            </AppProvider>
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
