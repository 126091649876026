import axios from "axios"
import { ImageStrapi } from "types/ImgeStrapi.type"
import { Utils } from "utils/utils"
import ENV from "./env.service"

const upload = async(blobUrl: string, ext: string): Promise<ImageStrapi | null> => {
    var form = new FormData()
    const name = `optinet_${Date.now()}.${ext}`

    const blob = (await axios({
        method: 'get',
        url: blobUrl,
        responseType: 'blob'
    })).data

    form.append("files", blob, name)

    try {
        const { token } = Utils.getParams()
        const response = await axios.post(`${ENV.strapi}/api/uploadfiles`, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'optinet': token
            }
        }).catch(error => {
            console.error(error)
            throw new Error(error)
         })
        const image: ImageStrapi = response?.data[0] || null
        return image
    } catch (error) {
        console.error(error)
        return null
    }
}

const remove = async(id: string) => {
    if (!id || id === "undefined") return true
    await axios.delete(`${ENV.strapi}/api/uploadfiles/${id}`, {
        headers: {
            'optinet': 'test123',
        }
    })
    return true
}

export const FilesService = {
    upload,
    remove
}
