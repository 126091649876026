import { ReactElement } from "react"
import { LoadingProvider } from "./Loading.context"

type AppProviderInput = { children: ReactElement }
const AppProvider = ({ children }: AppProviderInput) => {
    return (<LoadingProvider>
        {children}
    </LoadingProvider>)
}

export { AppProvider }