import { Block } from "models/Components.model"
import { BlockItemStringStyles } from "./BlockItemString.styled"

type BlockItemStringInput = {
    block: Block
    onChange(v: string): void
}
const BlockItemString = ({block, onChange}: BlockItemStringInput) => {
    return (<BlockItemStringStyles className="BlockItemString">
        <input value={block.value || ""} onChange={(e) => onChange(e.target.value)} />
    </BlockItemStringStyles>)
}

export { BlockItemString }