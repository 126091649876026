
import { AddBlock, SelectedBlocks } from "components"
import { useLoading } from "context/Loading.context"
import { Block } from "models/Components.model"
import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { ContextTypesState, SelectedBlocksState, SelectedBlockState } from "recoilStates"
import { ContentService } from "services/content.service"
import { HomeStyles } from "./home.styled"
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'
import { useCurrentContent } from "hooks/useCurrentContent"
import { toast } from "react-toastify"
import { ImagesControllerState } from "recoilStates/ImagesController.atom"


const Home = () => {
    const { setLoading } = useLoading()
    const [,setContextTypes] = useRecoilState(ContextTypesState)
    const [showAddBlock, setShowAddBlock] = useState<boolean>(false)
    const [selectedBlocks, setSelectedBlocks] = useRecoilState<Block[]>(SelectedBlocksState as any)
    const [imagesController] = useRecoilState<number[]>(ImagesControllerState as any)
    const [,setSelected] = useRecoilState<number>(SelectedBlockState)
    const { loadCurrentContent } = useCurrentContent()

    const loadContexTypes = async() => {
        setLoading(true)
        try {
            const data = await ContentService.getData()
            setLoading(false)
            setContextTypes(data)
            loadCurrentContent({ contextTypes: data })
        } catch (error) {
            console.error("Error on loadContexTypes", error)
            setLoading(false)
        }
    }
    
    useEffect(() => {
        loadContexTypes()
    }, [])

    const handlerOnCloseAddBlock = () => {
        setShowAddBlock(false)
    }

    const handlerOnAdd = (block: Block) => {
        if (
            block.uid === "blocks.optical-images" 
            && selectedBlocks.some(x => x.uid === "blocks.optical-images")
        ) {
            setShowAddBlock(false)
            return toast.error("Este bloque ya existe y no es repetible")
        }

        setShowAddBlock(false)
        const newList = [...selectedBlocks, block]
        setSelectedBlocks(newList)
        setSelected(newList.length - 1)
    }

    const handlerOnSave = async() => {
        try {
            setLoading(true)
            await ContentService.save({
                blocks: selectedBlocks,
                imagesController
            })
            await loadContexTypes()
            setLoading(false)
            toast.success("Se han guardado los cambios")
        } catch (error) {
            setLoading(false)
            console.error(error)
            toast.error("Error al intentar guardar")
        }

    }

    return (<HomeStyles className="Home cover">
        { showAddBlock && <AddBlock
            onAdd={handlerOnAdd}
            onClose={handlerOnCloseAddBlock}
        />}
        <div className="Header">
           <div className="Header-Buttons">
                <div className="btn" onClick={handlerOnSave}>
                    Save
                    <SaveIcon/>
                </div>
                <div className="btn" onClick={() => setShowAddBlock(true)}>
                    Add
                    <AddIcon/>
                </div>
           </div>
        </div>
        <SelectedBlocks/>
    </HomeStyles>)
}

export { Home }