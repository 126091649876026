
import { css } from "@emotion/react"

export const CoommonStyles = css`
    * {
        -webkit-overflow-scrolling: touch;
        box-sizing: border-box;
        font-family: sans-serif;
    }
    html, body, #root {
        margin: 0;
        width: 100%;
        height: 100%;
    }
    a {
        text-decoration: none;
        color: inherit;
        &:visited {
          color: inherit;
        }
    }
    .cover {
        width: 100%;
        height: 100%;
    }
    .imgCover {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
    .center {
      display: flex;
      justify-content: center;
      align-items: center;
      &.Y {
        flex-direction: column;
      }
    }
    .btn {
      height: 36px;
      min-width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #837fd4;
      color: white;
      user-select: none;
      cursor: pointer;
      padding: 0 20px;
      border-radius: 4px;
      transition: all 100ms ease;
      &:active {
        transform: scale(0.9);
      }
    }
    input {
      width: 300px;
      border: 1px solid rgb(227, 233, 243);
      padding: 0px calc(4.4rem) 0px 1rem;
      outline: 0px;
      border-radius: 2px;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      color: rgb(51, 55, 64);
      height: 34px;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }    
`