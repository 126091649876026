export type BlockTypes = "component" | "string" | "boolean" | "richtext" | "media" | "enumeration" | "colorpicker" | "integer"
export type Block = {
    name: string
    component: string
    max: number
    repeatable: boolean
    type: BlockTypes
    default: any
    components: BlockRecord
    value: any
    uid: string
    target: string
    customField?: string
    componentItems: {
        name: string
        components: BlockRecord
    }
}
export type BlockRecord = Record<string, Block>
export class Components {
    private data: any

    constructor(components: any) {
        const data: Record<string, BlockRecord> = components.reduce((acum: Record<string, {name: string, components: BlockRecord, uid: string}>, item: any) => {
            acum[item.uid] = {
                uid: item.uid,
                name: item.schema.name,
                components: item.schema.attributes
            }
            return acum
        }, {})

        Object.entries(data).forEach(([,x]) => {
            this.setComponents(x.components as any, data)
        })
        this.data = data
    }
    private setComponents(items: BlockRecord, list: Record<string, BlockRecord>) {
        Object.entries(items)?.forEach(([name,x]) => {
            if (x.type === "component" && !x.componentItems) {
                x.name = name
                x.componentItems = list[x.component as string] as any
                this.setComponents(x.componentItems as any, list)
            } else {
                if (typeof x.default !== "undefined") {
                    x.value = x.default
                }
            }
        })
    }
    getComponent(id: string): BlockRecord {
        return this.data[id]
    }
}
