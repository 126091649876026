import styled from "@emotion/styled"


export const HomeStyles = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content auto;
    .Header {
        display: flex;
        padding: 10px 10px;
        height: 60px;
        justify-content: flex-end;
        align-items: center;
        &-Buttons {
            display: flex;
            gap: 10px
        }
    }
`