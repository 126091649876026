import { FilesService } from "services/files.service"
import { Block, BlockRecord, Components } from "./Components.model"


export class ContentType {
    private data: any
    constructor(content: any, components: Components) {
        Object.entries(content.schema.attributes).forEach(([,values]: any) => {
            if (values.type === "dynamiczone") {
                values.components = values.components.reduce((acum: Record<string, any>, id: string) => {
                    acum[id] = components.getComponent(id)
                    return acum
                }, {})
            }
        })
        this.data = content.schema.attributes
    }

    getContent(): BlockRecord {
        return this.data.blocks.components
    }

    static getContextTypeByBlocks = async(blocks: Block[]) => {
        const data: any[] = []
        const images: number[] = []

        for (let bi = 0; bi < blocks.length; bi++) {
            const x = blocks[bi]
            const item: Record<string, any> = {
                __component: x.uid
            }
            const entriesComponents = Object.entries(x.components)

            for (let ix = 0; ix < entriesComponents.length; ix++) {
                const [name, x] = entriesComponents[ix]
                if (typeof x.value !== "undefined") {
                    if (!Array.isArray(x.value)) {
                        if (x.type === "media") {
                            // PROCESS MEDIA
                            const { blob, ext, id } = x?.value || {}
                            if (blob) {
                                // if (id) {
                                //     // remove old image
                                //     await FilesService.remove(id)
                                // }
                                const image = await FilesService.upload(blob, ext)
                                if (image) {
                                    item[name] = image.id
                                    images.push(image.id)  // add image to controller
                                }
                            } else if (id) {
                                // if exist image
                                item[name] = x.value.id
                                images.push(x.value.id) // add image to controller
                            }
                        } else {
                            item[name] = x.value
                        }
                    }
                    if (Array.isArray(x.value) && !x.target) {
                        const { repeatable } = x
                        const result: any = []
                        for (let iv = 0; iv < x.value.length; iv++) {
                            const val = x.value[iv]
                            const el: Record<string, any> = {}

                            const entries = Object.entries(val)
                            for (let index = 0; index < entries.length; index++) {
                                const [key, x]: any = entries[index]
                                if ((x && x.type && x.type === "media") || (val.mime && val.mime.includes("image" || "video"))) {
                                    // PROCESS MEDIA
                                    const { blob, ext, id } = x?.value || {}
                                    if (blob) {
                                        const image = await FilesService.upload(blob, ext)
                                        if (image) {
                                            el[key] = image.id
                                            images.push(image.id) // add image to controller
                                        }
                                    } else if (id) {
                                        // if exist image
                                        el[key] = x.value.id
                                        images.push(x.value.id) // add image to controller
                                    }
                                } else if (x.type === "component") {
                                    //FIXME: recursive
                                    if (x.repeatable) {
                                        //FIXME: not found for all
                                        el[key] = x?.value?.map((x: any) => ({
                                            ...Object.entries(x).reduce((acum: any, [name, { value }]: any) => {
                                                acum[name] = value
                                                return acum
                                            }, {})
                                        })) ?? []
                                    } else {
                                        el[key] = Object.entries(x && x?.value[0] || {}).reduce((acum: any, [name, {value}]: any) => {
                                            acum[name] = value
                                            return acum
                                        }, {})
                                    }
                                } else {
                                    el[key] = x?.value ?? ""
                                }
                            }
                            result.push(el)
                        }
                        if (repeatable) {
                            item[name] = result
                        } else {
                            item[name] = result[0] || {}
                        }
                    }
                    if (Array.isArray(x.value) && x.target) { // RELATION
                        item[name] = x.value
                    }
                }
            }
            data.push(item)
        }
        return {
            data,
            images
        }
    }
}
