import { Block, BlockRecord } from "models/Components.model"
import React, { useEffect, useState } from "react"
import { BlockItemByType } from "../BlockItemByType/BlockItemByType"
import { BlockItemComponentStyled } from "./BlockItemComponent.styed"
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import AddIcon from '@mui/icons-material/Add'
import { Utils } from "utils/utils"

type BlockItemComponentInput = {
    block: Block
    onChange(value: any): void
}
const BlockItemComponent = ({ block, onChange }: BlockItemComponentInput) => {
    
    const { value, repeatable } = block


    const [list, setList] = useState<BlockRecord[] | null>(value || null)
    const [select, setSelect] = useState<number>(-1)
    const { components } = block.componentItems
    const [animation, setAnimation] = useState<number>(-1)

    const onAdd = () => {
        const newList = [...list || [], components]
        setSelect(newList.length - 1)
        setList(newList)
    }

    const onSelect = (e: Event, value: number) => {
        const el = e.target as HTMLDivElement
        !el?.classList.contains("BlockItemComponent-Item-Header-Button") &&
            setSelect(value === select ? -1 : value)
    }

    const isSelected = (index: number) => {
        return index === select ? "BlockItemComponent-Item-Selected" : "" 
    }

    const handlerOnChange = (value: string, id: string, index: number) => {
        list && setList(list.map((x,i) => {
            if (index === i) {
                return {
                    ...x,
                    [id]: {
                        ...x[id],
                        value
                    }
                }
            } else {
                return x
            }
        }))
    }

    useEffect(() => {
        if (list) {
            onChange(list)
        }
    }, [list])

    const moveUp = (index: number) => {
        setSelect(-1)
        list && setList(Utils.moveArrayElement(
            list,
            index,
            index - 1
        ))
        setAnimation(index - 1)
    }

    const moveDown = (index: number) => {
        setSelect(-1)
        list && setList(Utils.moveArrayElement(
            list,
            index,
            index + 1
        ))
        setAnimation(index + 1)
    }
    
    const onDelete = (index: number) => {
        list && setList(list.filter((x, i) => i !== index))
    }

    const getAnimationClass = (index: number) => {
        return animation === index
            ? "animate__animated animate__pulse"
            : ""
    }

    useEffect(() => {
        setTimeout(() => {
            animation !== -1 && setAnimation(-1)
        }, 250)
    }, [animation])

    const renderAddButton = () => {
        return (<div 
            className="BlockItemComponent-Add" 
            onClick={onAdd} 
            style={{borderTopStyle: !list?.length ? "none" : "solid"}}
        >
            <AddIcon/>
            <label>Añadir nueva entrada</label>
        </div>)
    }

    return (
        <BlockItemComponentStyled className="BlockItemComponent">
            <ul>
                {list && list?.map((item, index) => {

                    const first = index === 0
                    const last = index === list.length - 1

                    return (
                        <li key={index} className={`BlockItemComponent-Item ${isSelected(index)} ${getAnimationClass(index)}`}>
                            <div className="BlockItemComponent-Item-Header" onClick={(e) => onSelect(e as any, index)}>
                                <label>{item?.id || ""}</label>
                                <div className="BlockItemComponent-Item-Header-Buttons center">
                                    <div 
                                        className="center BlockItemComponent-Item-Header-Button BlockItemComponent-ArrowUp" 
                                        onClick={() => !first && moveUp(index)}
                                        style={{opacity: first ? 0.4 : 1}}
                                    > 
                                        <ArrowUpwardIcon/>
                                    </div>
                                    <div 
                                        className="center BlockItemComponent-Item-Header-Button BlockItemComponent-ArrowDown" 
                                        onClick={() => !last && moveDown(index)}
                                        style={{opacity: last ? 0.4 : 1}}
                                    >
                                        <ArrowDownwardIcon/>
                                    </div>
                                    <div className="center BlockItemComponent-Item-Header-Button" onClick={() => onDelete(index)}><DeleteIcon/></div>
                                </div>
                            </div>
                            {index === select &&  (<div className="BlockItemComponent-Item-Content">
                                {Object.entries(item).filter(([id]) => id !== "id").map(([id, x]) => {
                                    return (<React.Fragment key={id}>
                                        <p className="BlockItemComponent-Item-Content-Title">{id}</p>
                                        <BlockItemByType 
                                            key={id} 
                                            block={x} 
                                            onChange={(v) => handlerOnChange(v, id, index)} 
                                        />
                                    </React.Fragment>)
                                })}
                            </div>)}
                        </li>
                    )
                })}
            </ul>

            {repeatable && renderAddButton()}
            {repeatable === false && (!list ? renderAddButton() : (list && list?.length < 1 && renderAddButton()))}
        

        </BlockItemComponentStyled>
    )
}

export { BlockItemComponent }
