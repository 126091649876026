import axios from "axios"
import { Block, Components } from "models/Components.model"
import { ContentType } from "models/ContentType.model"
import { Utils } from "utils/utils"
import ENV from "./env.service"
// import { FilesService } from "./files.service"

const getContent = async() => {
    const { token } = Utils.getParams()
    return (await axios.get(`${ENV.strapi}/api/optical-components`, {
        headers: {
            optinet: token
        }
    })).data || {}
    // Strapi 3 version below
    // const content = "application::opticals.opticals"
    // return (await axios.get(`${ENV.strapi}/content-type-builder/content-types/${content}`)).data?.data || {}
}

const getComponents = async() => {
    const { token } = Utils.getParams()
    return (await axios.get(`${ENV.strapi}/api/components`, {
        headers: {
            optinet: token
        }
    })).data || {}
    // Strapi 3 version below
    // return (await axios.get(`${ENV.strapi}/content-type-builder/components`)).data?.data || {}
}

const getData = async() => {
    const components = new Components(await getComponents())
    const content = new ContentType(await getContent(), components)
    return content.getContent()
}


const getCurrentContent = async() => {
    // const data = (await axios.get(`${ENV.strapi}/opticals?storelocator_id=${1893}`)).data
    // return data?.length ? data[0].blocks : []

    const { token } = Utils.getParams()
    const data = (await axios.get(`${ENV.strapi}/api/store`, {
        headers: {
            optinet: token
        }
    })).data
    return data ? data.blocks : []
}

type SaveInput = {
    blocks: Block[]
    imagesController: number[]
}
const save = async({ blocks, imagesController }: SaveInput) => {
    const { token } = Utils.getParams()

    const {
        data: blocksData,
        images
    } = await ContentType.getContextTypeByBlocks(blocks)

    // FIXME: move logic to strapi backend in the update store
    // remove unused images

    const unusedImages = imagesController.filter(id =>  !images.includes(id))


    // imagesController.forEach(id => {

    // })

    // for (let index = 0; index < imagesController.length; index++) {
    //     const id = imagesController[index]
    //     if (!images.includes(id)) {
    //         // remove image
    //         await FilesService.remove(String(id))
    //     }
    // }

    const result = (await axios.put(`${ENV.strapi}/api/store`, {
        blocks: blocksData,
        unusedImages
    }, {
        headers: {
            optinet: token
        }
    })).data || {}

    return result

    // const exist = (await axios.get(`${ENV.strapi}/opticals?storelocator_id=${storeid}`)).data
    // const id = exist?.length ? exist[0].id : false

    // const {
    //     data: blocksData,
    //     images
    // } = await ContentType.getContextTypeByBlocks(blocks)

    // // remove unused images
    // for (let index = 0; index < imagesController.length; index++) {
    //     const id = imagesController[index]
    //     if (!images.includes(id)) {
    //         // remove image
    //         await FilesService.remove(String(id))
    //     }
    // }

    // const data = {
    //     storelocator_id: String(storeid),
    //     // store: "es",
    //     // slug: "test",
    //     // name: "test",
    //     blocks: blocksData
    // }

    // if (id) {
    //     await axios.put(`${ENV.strapi}/opticals/${id}`, data, {
    //         headers: {
    //             'optinet': 'test123',
    //         }
    //     }).catch(error => {
    //         console.error(error)
    //         throw new Error(error)
    //     })
    // } else {
    //     await axios.post(`${ENV.strapi}/opticals`, data, {
    //         headers: {
    //             'optinet': 'test123',
    //         }
    //     }).catch(error => {
    //         console.error(error)
    //         throw new Error(error)
    //     })
    // }
}

const getService = async(serviceName: string) => {
    // const SERVICES: Record<string, string> = {
    //     "app::optical-service.optical-service": "optical-services"
    // }
    console.log(serviceName)
    const data = (await axios.get(`${ENV.strapi}/api/optical-services`)).data
    return data
}

export const ContentService = {
    getData,
    save,
    getCurrentContent,
    getService
}
