import { Block } from "models/Components.model"
import {BlockItemIntegerStyles} from "./BlockItemInteger.styled"

type BlockItemIntegerInput = {
    block: Block
    onChange(v: string): void
}
const BlockItemInteger = ({block, onChange}: BlockItemIntegerInput) => {
    return (<BlockItemIntegerStyles className="BlockItemString">
        <input type="number" value={block.value || ""} onChange={(e) => onChange(e.target.value)} />
    </BlockItemIntegerStyles>)
}

export { BlockItemInteger }
