
import { RichText } from "components/RichText/RichText"
import { Block } from "models/Components.model"
import { BlockItemRichTextStyles } from "./BlockItemRichText.styled"

type BlockItemRichTextInput = {
    block: Block
    onChange(value: string): void
}
const BlockItemRichText = ({block, onChange}: BlockItemRichTextInput) => {

    const handlerOnChange = (value: string) => {
        onChange(value)
    }

    return (<BlockItemRichTextStyles className="BlockItemRichText">
        <RichText
            onChange={handlerOnChange}
            value={block.value || ""}
        />
    </BlockItemRichTextStyles>)
}

export { BlockItemRichText }