import styled from "@emotion/styled"

export const AddBlockStyles = styled.div`
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    background-color: rgba(20,20,20, 0.6);
    animation-duration: 300ms;
    .AddBlock {
        &-Window {
            background-color: white;
            width: 90%;
            height: 90%;
            max-height: 750px;
            max-width: 1000px;
            border-radius: 4px;
            position: relative;
            padding: 15px;
            padding-top: 60px;
            animation-delay: 200ms;
            animation-duration: 300ms;
        }
        &-Close {
            width: 35px;
            height: 35px;
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
        &-Content {
            overflow-y: auto;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            align-content: flex-start;
            height: 100%;
        }
        &-Item {
            width: 265px;
            height: 170px;
            color: white;
            margin: 10px;
            cursor: pointer;
            border-radius: 4px;
            user-select: none;
            transition: all 100ms ease;
            flex-direction: column;
            label {
                pointer-events: none;
            }
            &:hover {
                transform: scale(1.1);
            }
            &-Image {
                width: 100%;
                padding: 0 10px;
                margin-bottom: 6px;
                img {
                    width: 100%;
                    opacity: 0.9;
                }
            }
        }
    }
`