import styled from "@emotion/styled"

export const SelectedBlocksStyles = styled.div`
    padding: 20px;
    overflow-y: auto;
    ul {
        border-top-style: solid;
        border-width: 1px;
        border-color: #e4eaf4;
    }
`