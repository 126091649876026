import { useLoading } from "context/Loading.context"
import { Block, BlockRecord } from "models/Components.model"
import { useRecoilState } from "recoil"
import { SelectedBlocksState } from "recoilStates"
import { ImagesControllerState } from "recoilStates/ImagesController.atom"
import { ContentService } from "services/content.service"

type CurrentContentData = Array<{__component: string} & Record<string, any>>
export const useCurrentContent = () => {
    const { setLoading } = useLoading()
    const [,setSelectedBlocks] = useRecoilState<Block[]>(SelectedBlocksState as any)
    const [,setImagesController] = useRecoilState<number[]>(ImagesControllerState as any)


    const setData = (data: CurrentContentData, contextTypes: BlockRecord) => {
        const blocks: Block[] = []
        const images: number[] = []

        data.forEach(x => {
            const select = contextTypes[x.__component]
            if (!select?.components) return
            const copy = Object.assign({}, select)
            copy.components = Object.entries(copy.components).reduce((acum: BlockRecord, [name, item]) => {
                if (item.type === "component") {
                    const repeat = item.repeatable
                    if (repeat) {
                        acum[name] = {
                            ...item,
                            ...(typeof x[name]?.map !== "undefined" && {
                                value: x[name]?.map((x: any) => {
                                    var result: any = {}
                                    Object.entries(x).forEach(([key, value]: any) => {

                                        if (key === "id") {
                                            result["id"] = value
                                            return result
                                        }
                                        const el = item.componentItems.components[key]
                                        if(el.type === "media") {
                                            images.push(value?.id)
                                        }
                                        if (el.type === "component") {
                                            result[key] = {
                                                ...el,
                                                ...(typeof value?.map !== "undefined" && {
                                                    value: value.map((x: any) => {
                                                        var result: any = {}
                                                        Object.entries(x).forEach(([key, v]: any) => {
                                                            if (key === "id") {
                                                                result["id"] = v
                                                                return result
                                                            }
                                                            const select = el.componentItems.components[key]
                                                            if (select) {
                                                                result[key] = {
                                                                    ...select,
                                                                    value: v
                                                                }
                                                            }
                                                        })
                                                        return result
                                                    })
                                                })
                                            }
                                        } else if (el) {
                                            result[key] = {
                                                ...el,
                                                value
                                            }
                                        }
                                    })
                                    return result
                                })
                            })
                        }
                    } else {
                        acum[name] = {
                            ...item,
                            ...(typeof x[name] !== "undefined" && x[name] !== null && {
                                value: [Object.entries(x[name]).reduce((acum: Record<string, any>, [key, value]: any[]) => {
                                    if (key === "id") {
                                        acum["id"] = value
                                        return acum
                                    }

                                    const el = item.componentItems.components[key]
                                    if(el.type === "media") {
                                        images.push(value?.id)
                                    }
                                    if (el) {
                                        acum[key] = {
                                            ...el,
                                            value
                                        }
                                    }
                                    return acum

                                }, {})]
                            })
                        }
                    }
                } else {
                    if (item.type === "media") {
                        x[name]?.id && images.push(x[name].id)
                    }
                    acum[name] = {
                        ...item,
                        ...(typeof x[name] !== "undefined" && {
                            value: x[name]
                        })
                    }
                }

                return acum
            }, {})
            blocks.push(copy)
        })
        setSelectedBlocks(blocks)
        setImagesController(images)
    }


    type LoadCurrentContentInput = {
        contextTypes: BlockRecord
    }
    const loadCurrentContent = async({contextTypes}: LoadCurrentContentInput) => {
        setLoading(true)
        try {
            const data = await ContentService.getCurrentContent()
            setData(data, contextTypes)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error(error)
        }
    }

    return {
        loadCurrentContent
    }
}
