import { Block } from "models/Components.model"
import { BlockItemStyles } from "./BlockItem.styled"
import { BlockItemByType } from "./components"
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { GetAddBlockData } from "components/AddBlock/AddBlock.data"


type BlockItemInput = {
    block: Block
    expand: boolean
    onClick(): void
    onChange(id: string, value: any): void
    onDelete(): void
    onMoveUp(): void
    onMoveDown(): void
    first: boolean
    last: boolean
    animation: boolean
    color: string
}
const BlockItem = ({
    block,
    onClick,
    expand,
    onChange,
    onDelete,
    onMoveUp,
    onMoveDown,
    last,
    first,
    animation,
    color
    }: BlockItemInput) => {


    const { name, image } = GetAddBlockData(block.name)

    const handlerOnOpen = (e: Event) => {
        const el: HTMLDivElement = e.target as HTMLDivElement
        if (!el?.classList.contains("BlockItem-Header-Buttons-Button")) {
            onClick()
        }
    }

    const handlerOnMoveUp = () => !first && onMoveUp()
    const handlerOnMovedown = () => !last && onMoveDown()

    const getAnimationClass = () => {
        return animation
            ? "animate__animated animate__pulse"
            : ""
    }

    return (<BlockItemStyles className={`BlockItem ${getAnimationClass()}`} last={last} first={first} color={color} expand={expand}>
        <div className="BlockItem-Header" onClick={handlerOnOpen as any}>
            <div className="BlockItem-Header-Color center">
                <img src={`/images/blocks/${image}.png`} />
            </div>
            <p className="BlockItem-Header-Title">{name}</p>
            <div className="BlockItem-Header-Buttons center">
                <div className="center BlockItem-Header-Buttons-Button BlockItem-ArrowUp" onClick={handlerOnMoveUp}> <ArrowUpwardIcon/></div>
                <div className="center BlockItem-Header-Buttons-Button BlockItem-ArrowDown" onClick={handlerOnMovedown}> <ArrowDownwardIcon/></div>
                <div className="center BlockItem-Header-Buttons-Button" onClick={onDelete}><DeleteIcon/></div>
            </div>
        </div>
        {expand && (<div className="BlockItem-Content">
            <div className="BlockItem-Content-Wrap animate__animated animate__fadeInDown">
                {block.components && Object.entries(block.components).map(([id,item], index) => {

                    if (id === "analyticsPromotion") return null

                    const { type, name } = item
                    const handlerChange = (value: any) => {
                        onChange(id, value)
                    }
                    return (<div key={index} style={{width: type === "component" ? "100%" : "unset"}}>
                        <p className="BlockItem-Title">{ name || id }</p>
                        <BlockItemByType
                            block={item}
                            onChange={handlerChange}
                        />
                    </div>)
                })}
            </div>
        </div>)}
    </BlockItemStyles>)
}

export { BlockItem }
