import { Block } from "models/Components.model"
import { BlockItemBoolean } from "../BlockItemBoolean/BlockItemBoolean"
import { BlockItemColor } from "../BlockItemColor/BlockItemColor"
import { BlockItemComponent } from "../BlockItemComponent/BlockItemComponent"
import { BlockItemEnum } from "../BlockItemEnum/BlockItemEnum"
import { BlockItemImage } from "../BlockItemImage/BlockItemImage"
import { BlockItemRichText } from "../BlockItemRichText/BlockItemRichText"
import { BlockItemRelation } from "../BlockItemRelation/BlockItemRelation"
import { BlockItemString } from "../BlockItemString/BlockItemString"
import {BlockItemInteger} from "../BlockItemInteger/BlockItemInteger"

type BlockItemByTypeInput = {
    block: Block
    onChange(value: string): void
}
const BlockItemByType = ({ block, onChange }: BlockItemByTypeInput) => {
    const { type } = block

    return (
        <>
            {type === "string" && block.customField !== "plugin::color-picker.color" && <BlockItemString block={block} onChange={onChange} />}
            {type === "richtext" && <BlockItemRichText block={block} onChange={onChange} />}
            {type === "boolean" && <BlockItemBoolean block={block} onChange={onChange} />}
            {type === "component" && <BlockItemComponent block={block} onChange={onChange} />}
            {type === "media" && <BlockItemImage block={block} onChange={onChange} />}
            {type === "string" && block.customField === "plugin::color-picker.color" && <BlockItemColor block={block} onChange={onChange} />}
            {type === "enumeration" && <BlockItemEnum block={block} onChange={onChange} />}
            {type === "integer" && <BlockItemInteger block={block} onChange={onChange} />}

            {typeof block.target !== "undefined" && <BlockItemRelation block={block} onChange={onChange as any} />}

        </>
    )
}

export { BlockItemByType }
