import { Block } from "models/Components.model"

type BlockItemEnumInput = {
    block: Block
    onChange(v: string): void
}
const BlockItemEnum = ({block}: BlockItemEnumInput) => {
    return (<div className="BlockItemEnum">
        <h1>enumeration</h1>
    </div>)
}

export { BlockItemEnum }