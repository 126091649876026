function moveArrayElement<X>(array: Array<X>, from: number, to: number): Array<X> {
    const copy = Object.assign([], array)
    copy.splice(to, 0, copy.splice(from, 1)[0])
    return copy
}

type getParamsOutput = Record<string, string>
const getParams = (): getParamsOutput => {
    return (location.href.split("?")[1] || "").split("&").reduce((acum: getParamsOutput, item) => {
        const [name, value] = item.split("=")
        acum[name] = value
        return acum
    }, {})
}


export const Utils = {
    moveArrayElement,
    getParams
}