import { Block } from "models/Components.model"
import { useEffect, useState } from "react"
import { BlockItemImageStyles } from "./BlockItemImage.styled"
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import ENV from "services/env.service"

type BlockItemImageInput = {
    block: Block
    onChange(value: any): void
}
const BlockItemImage = ({ block, onChange }: BlockItemImageInput) => {
    const [image, setImage] = useState<{blob: any, ext: any, id: any, url: any}>({
        blob: null,
        ext: block?.value?.length > 0 && block?.value[0].ext || block?.value?.ext && block.value.ext || null,
        id: block?.value?.length > 0 && block?.value[0].id  || block?.value?.id && block.value.id || null,
        url: block?.value?.length > 0 && block?.value[0].url  || block?.value?.url && block.value.url || null,
    })

    const IMAGE_URL = block?.value?.length > 0 && block?.value[0].url ? `${ENV.strapi}${block.value[0].url}` : block?.value?.url ? `${ENV.strapi}${block.value.url}` : null

    const handlerOnChangeInput = (e: Event) => {
        const fr = new FileReader()
        const file = (e.target as any).files[0]
        const ext = file.name.split(".").pop()
        fr.onload = function() {
            if (!fr.result) return
            const blob = new Blob([fr.result])
            const url = URL.createObjectURL(blob)
            setImage({
                ...image,
                blob: url,
                ext
            })
        }

        fr.readAsArrayBuffer(file)
    }

    useEffect(() => {
        if (image) {
            onChange(image) // send image and format
        }
    }, [image])

    return (<BlockItemImageStyles className="BlockItemImage">

        <div className="BlockItemImage-File">
            <div className="BlockItemImage-File-Layer cover center">

                { image.blob || IMAGE_URL
                    ? <img className="BlockItemImage-File-Layer-Image" src={image.blob || IMAGE_URL}/>
                    : <AddPhotoAlternateIcon className="BlockItemImage-File-Layer-Icon"/>
                }

            </div>
            <input
                className="BlockItemImage-Input cover"
                type="file"
                onChange={handlerOnChangeInput as any}
                accept=".png,.PNG,.jpg,.JPG"
            ></input>
        </div>


    </BlockItemImageStyles>)
}
export { BlockItemImage }
