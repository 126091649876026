import styled from "@emotion/styled"

export const BlockItemRelationStyles = styled.div`
    display: flex;
    .BlockItemRelation {
        &-Options, &-Selected {
            width: 250px;
            margin-right: 20px;
            ul {
                li {
                    height: 27px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    user-select: none;
                    border-bottom-style: solid;
                    border-width: 1px;
                    border-color: #e4eaf4;
                    font-size: 13px;
                    svg {
                        color: #7f7f7f;
                    }
                    label {
                        pointer-events: none;
                    }
                }
            }
        }
    }
`