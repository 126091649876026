import React, { createContext, ReactElement, useEffect, useRef } from "react"
import styled from "@emotion/styled"
import CircularProgress from '@mui/material/CircularProgress'


interface LoadingProps {
    setLoading(val: boolean): void
}

export const LoadingContext = createContext<LoadingProps | null>(null)
export function useLoading(): LoadingProps {
    const context = React.useContext(LoadingContext)
    if (!context) {
        throw new Error("useLoading must be used within an LoadingProvicer")
    }
    return context
}

const LoadingSyles = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    background-color: rgba(200,200,200,0.6);
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    .Loading {
        &-Window {
            background-color: transparent;
            width: 340px;
            height: 200px;
            animation-duration: 300ms;
        }
    }
`

const LoadingProvider = ({children}: {
    children: ReactElement
}): JSX.Element => {

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.setLoading = (val: boolean) => {
                if (!ref?.current) return
                ref.current.style.display = val ? "flex" : "none"
            }
        }
    }, [])


    const ctx: LoadingProps = {
        setLoading: (val: boolean) => {
            if (!ref?.current) return
            ref.current.style.display = val ? "flex" : "none"
        }
    }

    return (<LoadingContext.Provider value={ctx}>
        <LoadingSyles className="Loading" ref={ref}>
            <div className="Loading-Window center Y animate__animated animate__zoomIn">
                <CircularProgress size={80}/>
            </div>
        </LoadingSyles>
        {children}
    </LoadingContext.Provider>)
}

export { LoadingProvider }