import styled from "@emotion/styled"

export const ColorPickerStyled = styled.div`
    padding-bottom: 30px;
    .ColorPicker {
        &-Color {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            border-style: solid;
            border-width: 1px;
            border-color: #b3b3b3;
        }
        &-Wrap {
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 2;
            position: relative;
        }
        &-Layer {
            position: fixed;
            inset: 0px;
            z-index: 99999;
        }
        &-Picker {
            position: absolute;
            z-index: 99999;
        }
    }
    .chrome-picker  {
        margin-top: 10px;
        position: absolute;
        z-index: 99999;
        visibility: hidden;
    }
`