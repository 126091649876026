import { ColorPickerStyled } from "./BlockItemColor.styled"
import { useState, useEffect, useRef } from "react"
import { ChromePicker } from "react-color"
import { Block } from "models/Components.model"


type BlockItemColorInput = {
    block: Block
    onChange(v: string): void
}
const BlockItemColor = ({onChange, block}: BlockItemColorInput) => {
      const [showPicker, setShowPicker] = useState(false)
      const [color, setColor] = useState(block.value || "#deddfe")
      const ref = useRef(null)
    
      const handlerChange = ({ hex }: any) => {
        setColor(hex)
      }
    
      const setPositionIsOutTheScreen = () => {
        const wrap: HTMLDivElement = ref.current as any
        const layer = wrap.querySelector(".ColorPicker-Layer") as HTMLDivElement
        const picker = wrap.querySelector(".chrome-picker") as HTMLDivElement
        if (layer && picker) {
          const outTheScreen =
            picker.offsetHeight >=
            layer.offsetHeight - wrap.getBoundingClientRect().y
    
            outTheScreen && (picker.style.marginTop = "-320px")
        }
        picker && (picker.style.visibility = "visible")
      }

      useEffect(() => {
          if (!showPicker && block.value !== color) {
            onChange(color)
          }
      }, [showPicker])

      useEffect(() => {
        if (ref.current && showPicker) {
          setPositionIsOutTheScreen()
        }
      }, [showPicker, ref.current])
    
      return (
        <ColorPickerStyled className="ColorPicker">
          <div
            className="ColorPicker-Color"
            style={{ backgroundColor: color }}
            onClick={() => setShowPicker(!showPicker)}
          ></div>
          {showPicker && (
            <div className="ColorPicker-Wrap" ref={ref}>
              <div
                className="ColorPicker-Layer"
                onClick={() => setShowPicker(false)}
              ></div>
              <ChromePicker color={color} onChange={handlerChange} />
            </div>
          )}
        </ColorPickerStyled>
      )
    }
export { BlockItemColor }