import { atom } from "recoil"

export const SelectedBlocksState = atom({
    key: "selectedBlocks",
    default: []
})

export const SelectedBlockState = atom({
    key: "selectedBlock",
    default: -1
})