interface EnvIf {
    strapi: string
    isProduction: boolean
}

const isProduction = process.env.NODE_ENV === "production"

const ENV: EnvIf = {
    get strapi() {
        if (!isProduction) return "https://opticalia-update-strapi.dev.mrmilu.com"
        return String(
            process.env.REACT_APP_STRAPI || "https://opticalia-update-strapi.mrmilu.com"
        )
    },
    get isProduction() {
        return process.env.NODE_ENV === "production"
    }
}
export default ENV
