import { useColors } from "hooks/useColors"
import { Block } from "models/Components.model"
import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { ContextTypesState, SelectedBlocksState, SelectedBlockState } from "recoilStates"
import { Utils } from "utils/utils"
import { BlockItem } from "./components"
import { SelectedBlocksStyles } from "./SelectedBlocks.styled"

const SelectedBlocks = () => {
    const [selectedBlocksList, setSelectedBlockList] = useRecoilState<Block[]>(SelectedBlocksState as any)
    const [contextTypes] = useRecoilState(ContextTypesState)
    const [selected, setSelected] = useRecoilState<number>(SelectedBlockState)
    const [animationMove, setAnimationMove] = useState<number>(-1)
    const { getColorByIndex } = useColors()
    const [colors, setColors] = useState<any>({})

    const handlerOnSelect = (index: number) => {
        setSelected(index !== selected ? index : -1)
    }

    const handlerOnChange = (id: string, value: string, index: number) => {
        setSelectedBlockList(selectedBlocksList.map((x, i) => {
            if (i === index) {
                return  {
                    ...x,
                    components: {
                        ...x.components,
                        [id]: {
                            ...x.components[id],
                            value
                        }
                    }
                }
            } else {
                return x
            }
        }))
    }
    const hadlerOnDelete =(index: number) => {
        setSelectedBlockList(selectedBlocksList.filter((x,i) => i !== index ))
    }

    const handlerOnMoveDown = (index: number) => {
        setSelected(-1)
        setSelectedBlockList(Utils.moveArrayElement(
            selectedBlocksList,
            index,
            index + 1
        ))
        setAnimationMove(index + 1)
    }
    const handlerOnMoveUp = (index: number) => {
        setSelected(-1)
        setSelectedBlockList(Utils.moveArrayElement(
            selectedBlocksList,
            index,
            index - 1
        ))
        setAnimationMove(index -1)
    }

    useEffect(() => {
        setTimeout(() => 
            animationMove !== -1 && setAnimationMove(-1)
        , 220)
    }, [animationMove])
    
    useEffect(() => {
        setColors(Object.keys(contextTypes).reduce((acum: Record<string, string>, item, index) => {
            acum[item] = getColorByIndex(index)
            return acum
        }, {}) || {})
    }, [contextTypes])


    return (
        <SelectedBlocksStyles className="SelectedBlocks">
            <ul>
                {selectedBlocksList.map((block, index) => (
                    <BlockItem 
                        key={index} 
                        block={block} 
                        expand={index === selected}
                        onClick={() => handlerOnSelect(index)}
                        onChange={(id, value) => handlerOnChange(id, value, index)}
                        onDelete={() => hadlerOnDelete(index)}
                        onMoveDown={() => handlerOnMoveDown(index)}
                        onMoveUp={() => handlerOnMoveUp(index)}
                        first={index === 0}
                        last={index === selectedBlocksList.length - 1}
                        animation={animationMove === index}
                        color={colors[block.uid]}
                    />
                ))}
            </ul>
        </SelectedBlocksStyles>
    )
}

export { SelectedBlocks }
