const COLORS = [
    "#8257cf",
    "#498ed4",
    "#f06a6a",
    "#46425c",
    "#837fd4",
    "#ea8363",
    "#78a4a9",
    "#ca7eba"
]

export const useColors = () => {
    const getColorByIndex = (index: number): string => {
        return COLORS[Math.abs(index % COLORS.length)]
    }
    const getRandomColor = () => {
        const n = Math.floor(Math.random() * COLORS.length)
        return COLORS[n]
    }
    return {
        getColorByIndex,
        getRandomColor
    }
}