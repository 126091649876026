import styled from "@emotion/styled"

export const BlockItemStyles = styled.li<{
    last: boolean
    first: boolean
    color: string
    expand: boolean
}>`
    animation-duration: 200ms;
    .BlockItem {
        &-ArrowUp {
            ${props => props.first && `
                opacity: 0.4;
            `}
        }
        &-ArrowDown {
            ${props => props.last && `
                opacity: 0.4;
            `}
        }
        &-Title {
            margin: 0;
            margin-bottom: 10px;
            font-size: 14px;
        }
        &-Header {
            height: 68px;
            background-color: #f7f7f7;
            align-items: center;
            padding: 0 10px;
            user-select: none;
            display: grid;
            grid-template-columns: max-content auto max-content;
            border-style: solid;
            border-top-style: unset;
            border-width: 1px;
            border-color: #e4eaf4;
            cursor: pointer;
            transition: all ease 200ms;
            ${props => props.expand && `
                background-color: #e4f0fc;
                color: #007fff;
                border-color: #abd6ff;
                border-style: solid;
                transform: scale(1.01);
            `}
            &:active {
                transform: scale(0.98);
            }
            p {
                margin: 0;
            }
            &-Color {
                width: 112px;
                height: 80%;
                margin-right: 15px;
                border-radius: 4px;
                background-color: ${props => props.color};
                img {
                    width: 100%;
                    padding: 12px;
                }
            }
            &-Buttons {
                &-Button {
                    height: 100%;
                    width: 30px;
                    cursor: pointer;
                    color: #7f7f7f;
                    svg {
                        pointer-events: none;
                    }
                }
            }
        }
        &-Content {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
            gap: 20px;
            overflow: hidden;
            border-style: solid;
            border-width: 1px;
            border-color: #e4eaf4;
            padding: 20px;
            &-Wrap {
                animation-duration: 300ms;
            }
        }
    }
`