type BlockDataItem = {
    name: string
    image: string
}
const BLOCKS_DATA: Record<string, BlockDataItem> = {
    "optical-card-services": {
        name: "Servicios Disponibles",
        image: "services",
    },
    "featured-banner": {
        name: "Sobre Nosotros",
        image: "featured-banner"
    },
    "brands-slider": {
        name: "Nuestras Marcas",
        image: "brands"
    },
    "optical-card-promotion": {
        name: "Destacados",
        image: "card-promotion"
    },
    "service-and-promo-banner": {
        name: "Promociones",
        image: "ServiceAndPromoBanner"
    },
    "optical-card-warranty": {
        name: "Información",
        image: "CardWarranty"
    },
    "optical-images": {
        name: "Imágenes de la Óptica",
        image: "image"
    },
    "our-optics": {
        name: "Nuestras Ópticas",
        image: "our-optics"
    }
}
export const GetAddBlockData = (name: string): BlockDataItem =>
    BLOCKS_DATA[name] || { name: "", image: "" }
