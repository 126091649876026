import styled from "@emotion/styled"
import { useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import { convertToRaw } from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
const RichTextStyles = styled.div`
    height: 100%;
    display: flex;
    width: 100%;
    .rdw-editor-wrapper {
        display: grid;
        grid-template-rows: max-content auto;
        width: 100%;
    }
    .rdw-editor-toolbar {
        margin-bottom: 0;
    }
    .rdw-editor-main {
        padding: 0 15px;
        border-width: 1px;
        border-color: #e4eaf4;
        border-style: solid;
        border-top: unset;
    }
`
type RichTextInput = {
    value?: string
    onChange(value: string): void
}
const RichText = ({ onChange, value }: RichTextInput) => {
    const [editorState, setEditorState] = useState<any>(null)

    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState)
    }

    const handlerOnChange = () => {
        const value = draftToMarkdown(convertToRaw(editorState.getCurrentContent()))
        onChange(value)
    }


        return (
            <RichTextStyles>
            <Editor
                initialContentState={markdownToDraft(value || "")}
                toolbar={{
                    options: ["inline", "blockType", "list", "link", "history"],
                    inline: {
                        options: ["bold", "italic"],
                    },
                    blockType: {
                        options: ["Normal", "H1", "H2"],
                    },
                    list: {
                        options: ["unordered"],
                    },
                }}
                onChange={handlerOnChange}
                onEditorStateChange={onEditorStateChange}
            />
        </RichTextStyles>
    )
}

export { RichText }
