import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import { Block } from "models/Components.model"
import { useState } from "react"
import { BlockItemBooleanStyles } from "./BlockItemBoolean.styled"

type BlockItemBooleanInput = {
    block: Block
    onChange(value: any): void
    
}
const BlockItemBoolean = ({ block, onChange }: BlockItemBooleanInput) => {
    const [selected, setSelected] = useState<string | null>(
        typeof block.value === "boolean"
        ? (block.value ? "on" : "off")
        : null
    )
    
    
    const handlerOnChange = (ev: unknown, val: string) => {
        setSelected(val)
        onChange(val === 'on' ? true : false)
    }

    return <BlockItemBooleanStyles className="BlockItemBoolean">
        <ToggleButtonGroup
            color="primary"
            onChange={handlerOnChange}
            value={selected}
            exclusive
        >
            <ToggleButton value="off">OFF</ToggleButton>
            <ToggleButton value="on">ON</ToggleButton>
        </ToggleButtonGroup>
    </BlockItemBooleanStyles>
}

export { BlockItemBoolean }